<template>
  <div id="two-enzyme">
    <div v-for="(item, idx) in display" :key="idx">
      {{ item.text }} Enzyme Assay

      <hr />

      <!-- Sampe Date -->
      <datepicker
        v-if="logicBypassCheck(`${item.name}SampleDate`, 'visibility')"
        v-model="content.patientDiagnosis[`${item.name}SampleDate`]"
        :label="`${item.text} Sample Date`"
        :name="`${item.name}SampleDate`"
        :placeholder="`Enter ${item.text} Sample Date`"
        :content="content"
        :logic="logic"
        :errors="errors"
        :isDisable="handleLogicStatement(`${item.name}SampleDate`, 'disable')"
        @changeEvent="updateData"
      />

      <!-- Assay Method -->
      <dropdown
        v-if="logicBypassCheck(`${item.name}AssayMethod`, 'visibility')"
        v-model="content.patientDiagnosis[`${item.name}AssayMethod`]"
        :label="`${item.text} Assay Method`"
        :name="`${item.name}AssayMethod`"
        :content="content"
        :options="options.assayMethodOpt"
        :logic="logic"
        :errors="errors"
        :isDisable="handleLogicStatement(`${item.text} Assay Method`, 'disable')"
        @changeEvent="updateData"
      />

      <!-- Other Assay Method -->
      <text-field
        v-if="handleLogicStatement(`${item.name}OtherAssayMethod`, 'visibility')"
        v-model="content.patientDiagnosis[`${item.name}OtherAssayMethod`]"
        :label="`${item.text} Other Assay Method`"
        :name="`${item.name}OtherAssayMethod`"
        :placeholder="`Enter ${item.text} Other Assay Method`"
        :content="content"
        :logic="logic"
        :errors="errors"
        @blurEvent="updateData"
      />

      <div class="row">
        <div class="col">
          <!-- Enzyme Activity -->
          <text-field
            v-if="logicBypassCheck(`${item.name}EnzymeActivity`, 'visibility')"
            v-model="content.patientDiagnosis[`${item.name}EnzymeActivity`]"
            :label="`${item.text} Enzyme Activity`"
            :name="`${item.name}EnzymeActivity`"
            :placeholder="`${item.text} Enter Enzyme Activity`"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement(`${item.text} Enzyme Activity`, 'disable')"
            @blurEvent="updateData"
          />
        </div>
        <div
          v-if="logicBypassCheck(`${item.name}EnzymeActivityUnit`, 'visibility')"
          class="col"
        >
          <!-- Enzyme Activity Unit -->
          <dropdown
            v-model="content.patientDiagnosis[`${item.name}EnzymeActivityUnit`]"
            :label="`${item.text} Enzyme Activity Unit`"
            :name="`${item.name}EnzymeActivityUnit`"
            :content="content"
            :options="options.enzymActivityOpt"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement(`${item.name}EnzymeActivityUnit`, 'disable')"
            @changeEvent="updateData"
            @updateFieldValue="updateFieldVal"
          />
        </div>
      </div>

      <!-- Normal Range -->
      <div class="row">
        <div
          v-if="logicBypassCheck(`${item.name}NormalRangeLow`, 'visibility')"
          class="col"
        >
          <text-field
            v-model="content.patientDiagnosis[`${item.name}NormalRangeLow`]"
            :label="`${item.text} Range Low`"
            :name="`${item.name}NormalRangeLow`"
            :placeholder="`${item.text} Enter Range Low`"
            type="double"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement(`${item.name}NormalRangeLow`, 'disable')"
            @blurEvent="updateData"
          />
        </div>
        <div
          v-if="logicBypassCheck(`${item.name}NormalRangeHigh`, 'visibility')"
          class="col"
        >
          <text-field
            v-model="content.patientDiagnosis[`${item.name}NormalRangeHigh`]"
            :label="`${item.text} Range High`"
            :name="`${item.name}NormalRangeHigh`"
            type="double"
            :placeholder="`Enter ${item.text} Range High`"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement(`${item.name}NormalRangeHigh`, 'disable')"
            @blurEvent="updateData"
          />
        </div>
      </div>

      <div class="row" v-if="item.name === 'first'">
        <div class="col">
          <!-- Have any patient's siblings been diagnosesd with pompe -->
          <dropdown
            v-if="logicBypassCheck('patientSiblingsHavePompe', 'visibility')"
            v-model="content.patientDiagnosis.patientSiblingsHavePompe"
            label="Have any patient's siblings been diagnosed with pompe"
            name="patientSiblingsHavePompe"
            :content="content"
            :options="options.patientSiblingOpt"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('patientSiblingsHavePompe', 'disable')"
            @changeEvent="updateData"
          />
        </div>
      </div>

      <div class="row" v-if="item.name === 'first'">
        <div class="col">
          <!-- number of siblings diagnosed -->
          <text-field
            v-if="handleLogicStatement('numberOfSiblingsDiagnosed', 'visibility')"
            v-model="content.patientDiagnosis.numberOfSiblingsDiagnosed"
            label="Number of Siblings Diagnosed"
            type="number"
            name="numberOfSiblingsDiagnosed"
            placeholder="Number of Siblings Diagnosed"
            additional-message="Type Number of subling diagnosed"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('numberOfSiblingsDiagnosed', 'disable')"
            @blurEvent="updateData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayForm: {
      type: String,
      default: 'both',
    },
    bypassLogic: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    updateData: {
      type: Function,
      required: true,
    },
    errors: {
      type: [Object, Array],
    },
    logic: {
      type: Object,
    },
    updateFieldVal: {
      type: Function,
    },
  },
  computed: {
    display() {
      if (this.displayForm === 'first') {
        return [this.forms[0]]
      }

      if (this.displayForm === 'second') {
        return [this.forms[1]]
      }

      return this.forms;
    }
  },
  data: () => {
    return {
      forms: [
        { name: "first", text: "First" },
        { name: "second", text: "Second" },
      ],
    };
  },
  methods: {
    logicBypassCheck(fieldName, state) {
      if (!this.bypassLogic) {
        return this.handleLogicStatement(fieldName, state);
      }

      return this.bypassLogic;
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(217, 217, 217);
  margin: 1em 0 0.5em 0;
  padding: 0;
}
label {
  font-weight: 500;
  font-size: 13px;
}
.form-group {
  margin-bottom: 10px;
}
</style>
